<template>
  <div class="official-website-article-dialog">
    <el-dialog class="official-website-article dialogArticleVisible" width="1000px" top="10vh" @close="dialogClose" :visible.sync="showOfficialWebsiteArticle" :show-close="false">
      <h2>选择文章</h2>
      <div class="form">
        <div class="fl clearfix" v-if="taskInfo.type === 2 || taskInfo.type === '专题' || (type === 'email' && taskInfo.type === 4)">
          <span class="tj_year fl">内容类型:</span>
          <el-select v-model="chooseTypeVal" placeholder="请选择" class="fl" style="width:120px;top: 3px;" @change="chooseTypeChange">
            <el-option v-for="item in typeOption" :key="item.value" :label="item.label" :value="item.value" />
          </el-select>
        </div>
        <div class="fl clearfix" v-if="chooseTypeVal === 2">
          <span class="tj_year fl">选择专题:</span>
          <el-select v-model="chooseTopicVal" placeholder="请选择" class="fl" style="width:120px;top: 3px;" @change="chooseTopicChange">
            <el-option v-for="item in topicOption" :key="item.subjectName" :label="item.subjectName" :value="item.subjectName" />
          </el-select>
        </div>
        <div class="fl clearfix" v-if="chooseTypeVal === 1">
          <span class="tj_year fl">选取年份:</span>
          <el-select v-model="chooseYearVal" placeholder="请选择" class="fl" style="width:120px;top: 3px;" @change="chooseYearChange">
            <el-option v-for="item in getYearOption(options)" :key="item" :label="item" :value="item" />
          </el-select>
        </div>
        <div class="fl clearfix" v-if="chooseTypeVal === 1">
          <span class="tj_year fl">选取刊期:</span>
          <el-select v-model="chooseIssueVal" placeholder="请选择" class="fl" style="width:120px;top: 3px;" @change="chooseIssueChange">
            <el-option v-for="item in getIssueOption(options)" :key="item" :label="item" :value="item" />
          </el-select>
        </div>
        <div class="fl clearfix">
          <span class="tj_year fl">栏目:</span>
          <el-select v-model="columnVal" clearable placeholder="请选择" class="fl" style="width:120px;top: 3px;" @change="columnChange">
            <el-option v-for="item in columnOptions" :key="item" :label="item" :value="item" />
          </el-select>
        </div>
        <el-button
          class="fr download"
          type="text"
          icon="el-icon-xz"
          @click="
            () => {
              handleDownloadIssueArticles()
            }
          "
          >下载</el-button
        >
        <!-- 文章列表 -->
        <SimpleTable
          ref="articleTableData"
          maxHeight="400"
          style="width:100%;margin-top:50px"
          noSerial
          noPagination
          :showChoose="showChoose"
          :hasSelect="articleType == 1"
          :tableData="articleTableData"
          :columnData="articleColumn"
          @onRowClick="handleRowClick"
          @onClickTitle="handleOpenUrl"
          @onChangeInput="handleOfficialWebsiteArticleChangeInput"
          @onChangeInputBlur="handleOfficialWebsiteArticleChangeInputBlur"
        />
        <div slot="footer" class="dialog-footer" style="margin-top:30px">
          <el-button type="primary" @click="saveOfficialArticle">确 定</el-button>
          <el-button @click="showOfficialWebsiteArticle = false">取 消</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import SimpleTable from '@/components/SimpleTable'
import { mapGetters } from 'vuex'
import { GetIssuesByMagId, exportIssueArticles, SaveOfficialArticle, UpdateArticleUrl, SaveOfficialArticleForWeChat, GetSubjectList, GetSubjectArticles, GetFirstArticles, getArticleColumn } from '@/api/selfPush'
import { GetIssueArticles } from '@/api/aggregation'
import { GetMagDetail } from '@/api/detail'
export default {
  name: 'officialWebsiteArticleDialog',
  computed: {
    ...mapGetters({
      platMagId: 'platMagId',
      platTenantId: 'platTenantId',
      platMagName: 'platMagName',
      magId: 'magId'
    }),
    currentTabName() {
      return this.currentTabIndex == 1 ? '邮件推送' : '微网刊'
    },
    showChooseType() {
      return this.taskInfo.type === 2 ? ture : false
    },
    showChoose:{
      get() {
        if (this.taskInfo.type === 4) {
         return this.articleType != 1
        }
        return this.chooseTypeVal === 2 || this.chooseTypeVal === 3
      },
      set() {}
    }
  },
  props: {
    type: {
      type: String,
      default: '' // 区分邮件和微网刊
    },
    topic: {
      type: String,
      default: ''
    },
    publicationTopic: {
      type: String,
      default: ''
    },
    taskId: {
      // type: Number,
      default: null
    },
    taskInfo: {
      typeof: Object,
      return: {}
    }
  },
  components: {
    SimpleTable
  },
  data() {
    return {
      articleType: null,
      basicInfo: '',
      chooseTypeVal: 1,
      chooseTopicVal: '',
      chooseYearVal: '',
      chooseIssueVal: '',
      showOfficialWebsiteArticle: false,
      options: [],
      articleTableData: [],
      articleTableDataAll: [],
      articleColumn: [
        {
          title: '推送文章标题',
          prop: 'title',
          notSortable: true,
          special: true
        },
        {
          title: '栏目',
          prop: 'columnBelong',
          notSortable: true,
          special: true
        },
        {
          title: '机构',
          prop: 'institutions',
          notSortable: true
        },
        {
          title: '作者',
          prop: 'authors',
          notSortable: true
        },
        {
          title: '刊期',
          prop: 'magNumber',
          notSortable: true,
          width: 50
        },
        {
          title: '关键词',
          prop: 'keywords',
          notSortable: true
        },
        {
          title: 'URL',
          prop: 'handleJumpUrl',
          notSortable: true,
          edit: true
        }
      ],
      typeOption: [
        {
          label: '整期',
          value: 1
        },
        {
          label: '专题',
          value: 2
        }
      ],
      topicOption: [],
      chooseId: '',
      columnVal: '',
      columnOptions: [],
    }
  },
  watch: {
    magId() {
      this.getIssuesByMagId()
      this.getMagDetail()
    },
    chooseTypeVal(n) {
      if (n === 1) {
        this.getIssuesByMagId()
      }
      if (n === 2) {
        this.getSubjectList()
      }
      if (n === 3) {
        this.getFirstArticles()
      }
    },
    chooseTopicVal() {
      this.getSubjectArticles()
    }
  },
  mounted() {
    if (this.magId) {
      this.getIssuesByMagId()
      this.getMagDetail()
      // console.log(this.taskInfo, 'taskInfo')
      if (this.taskInfo.type === 2) {
        this.getSubjectList()
      }
      if (this.taskInfo.type === 4) {
        this.getFirstArticles()
        this.typeOption = [
          {
            label: '整期',
            value: 1
          },
          {
            label: '专题',
            value: 2
          },
          {
            label: '优先出版',
            value: 3
          }
        ]
      }
    }
  },
  methods: {
    columnChange () {
      console.log(this.columnVal, 'columnVal', this.articleTableData)
      // this.getIssueArticleData()
      if (this.columnVal) {
        this.articleTableData = this.articleTableDataAll.filter(item => item.columnBelong === this.columnVal)
      } else {
        this.articleTableData = this.articleTableDataAll
      }
    },
    getArticleColumn() {
      this.columnVal = ''
      let params = {
        magId: this.magId,
        year: this.chooseYearVal,
        issue: this.chooseIssueVal,
        subjectName: this.chooseTopicVal
      }
      getArticleColumn(params).then((res) => {
       console.log(res, 'res')
       if (res && res.data && res.data.status === 0) {
        this.columnOptions = res.data.data
       }
      })
    },
    //获取当前期刊基本信息
    getMagDetail() {
      const params = {
        id: this.magId
      }
      GetMagDetail(params).then((res) => {
        if (res && res.data && res.data.status === 0) {
          const { data } = res.data
          this.basicInfo = data
        } else {
          const { message } = res.data
          this.$message.error(message)
        }
      })
    },
    //根据任务id获取期刊刊期列表
    getIssuesByMagId(val) {
      let params = {
        id: this.platMagId,
        magId: this.magId,
        size: 999
      }
      GetIssuesByMagId(params).then((res) => {
        if (res && res.data && res.data.status === 0) {
          const { data } = res.data
          this.options = data.content
          if (data.content.length > 0) {
            if (val === 'click') {
              this.dialogArticleVisible = true
            }
            this.chooseYearVal = this.getYearOption(this.options)[0]
            this.chooseIssueVal = this.getIssueOption(this.options)[0]
            this.getIssueArticleData()
          }
        }
      })
    },
    //根据年份和刊期获取文章列表
    getIssueArticleData() {
      this.articleTableData = []
      this.articleTableDataAll = []
      const params = {
        magId: this.magId,
        year: this.chooseYearVal,
        magNumber: this.chooseIssueVal,
        page: '',
        size: 999,
        sort: 'sequence,asc'
      }
      this.getArticleColumn()
      GetIssueArticles(params).then((res) => {
        if (res && res.data && res.data.status === 0) {
          this.articleTableData = res.data.data.content
          this.articleTableData.map((item, index) => {
            item.handleJumpUrl = item.htmlUrl ? item.htmlUrl : item.officialWebsiteUrl ? item.officialWebsiteUrl : ''
          })
          this.articleTableDataAll = JSON.parse(JSON.stringify(this.articleTableData))
          this.chooseId = ''
        } else {
          const { message = '' } = res.data
          this.$message.error(message)
        }
      })
    },
    // 专题列表查询
    getSubjectList() {
      const params = {
        magId: this.magId
      }
      GetSubjectList(params).then((res) => {
        if (res && res.data && res.data.status === 0) {
          const { data } = res.data
          this.topicOption = data
          this.chooseTopicVal = data[0] ? data[0].subjectName : ''
          this.getSubjectArticles()
        } else {
          const { message = '' } = res.data
          this.$message.error(message)
        }
      })
    },
    // 优先论文查询
    getFirstArticles() {
      const params = {
        magId: this.magId,
        size: 999
      }
      GetFirstArticles(params).then((res) => {
        if (res && res.data && res.data.status === 0) {
          const { data } = res.data
          this.articleTableData = data.content
          this.articleTableData.map((item, index) => {
            item.handleJumpUrl = item.htmlUrl ? item.htmlUrl : item.officialWebsiteUrl ? item.officialWebsiteUrl : ''
          })
        } else {
          const { message = '' } = res.data
          this.$message.error(message)
        }
      })
    },
    // 获取专题文章
    getSubjectArticles() {
      this.articleTableData = []
      const params = {
        magId: this.magId,
        subjectName: this.chooseTopicVal,
        size: 999
      }
      GetSubjectArticles(params).then((res) => {
        if (res && res.data && res.data.status === 0) {
          const { data } = res.data
          this.articleTableData = res.data.data.content
          this.articleTableData.map((item, index) => {
            item.handleJumpUrl = item.htmlUrl ? item.htmlUrl : item.officialWebsiteUrl ? item.officialWebsiteUrl : ''
          })
        } else {
          const { message = '' } = res.data
          this.$message.error(message)
        }
      })
    },
    // 官网文章获取年
    getYearOption(options) {
      let arr = [...new Set(options.map((item) => item.year))]
      return arr
    },
    // 官网文章获取期
    getIssueOption(options) {
      let arr = [...new Set(options.map((item) => (item.year === this.chooseYearVal ? item.issue : '')))]
      arr.map((item, index) => {
        if (!item) arr.splice(index, 1)
      })
      return arr
    },
    // 微网刊弹窗内容类型改变
    chooseTypeChange() {
      this.chooseTopicVal = ''
      this.$refs.articleTableData.initData()
      this.chooseId = ''
    },
    // 微网刊弹窗专题改变
    chooseTopicChange() {},
    // 微网刊弹窗年份改变
    chooseYearChange() {
      this.chooseIssueVal = this.getIssueOption(this.options)[0]
      this.getIssueArticleData()
    },
    // 微网刊弹窗期改变
    chooseIssueChange() {
      this.getIssueArticleData()
    },
    // 下载
    handleDownloadIssueArticles() {
      window.open(`${exportIssueArticles}?magId=${this.magId}&year=${this.chooseYearVal}&magNumber=${this.chooseIssueVal}&column=${this.columnVal}&page=size=100&sort=sequence,asc`, '_self')
    },
    // 跳转页面
    handleOpenUrl(chooseItem, columnItem) {
      if (chooseItem['handleJumpUrl']) {
        window.open(chooseItem['handleJumpUrl'])
      }
    },
    // 处理微网刊弹窗文章列表切换为input框
    handleOfficialWebsiteArticleChangeInput() {},
    // 处理微网刊弹窗文章列表为input框时失焦事件
    handleOfficialWebsiteArticleChangeInputBlur(val, prop, event, index, content, id) {
      let params = {
        articleId: id,
        url: content || ''
      }
      UpdateArticleUrl(params).then((resp) => {
        let { data, status } = resp.data
        if (status !== 0) {
          this.$message({
            type: 'error',
            message: data.message
          })
          this.getIssueArticleData()
        } else {
          this.$message({
            type: 'success',
            message: '修改成功!'
          })
          this.getIssueArticleData()
        }
      })
    },
    // 列表选中数据确定
    saveOfficialArticle() {
      const chooseList = [...this.$refs.articleTableData?.chooseList]
      let articleIds = ''
      if (this.taskInfo.type === 4) {
        articleIds = this.chooseId
        if (this.articleType) {
          articleIds = chooseList.map(v => v.id).join()
        }
      } else {
        this.articleTableData.map((item, index) => {
          articleIds = articleIds + item.id + (index === this.articleTableData.length - 1 ? '' : ',')
        })
      }
      if (this.type === 'email') {
        this.emailBtn(articleIds)
      }
      if (this.type === 'weChat') {
        this.weChatBtn(articleIds)
      }
    },
    // 邮件确定
    emailBtn(articleIds) {
      let params = {}
      if (this.taskInfo.type === 1) {
        // 整期
        params = {
          taskId: this.taskId,
          operator: sessionStorage.getItem('realName'),
          year: this.chooseYearVal,
          issue: this.chooseIssueVal
        }
      } else if (this.taskInfo.type === 2) {
        // 专题
        if (this.chooseTypeVal == 2) {
          params = {
          taskId: this.taskId,
          operator: sessionStorage.getItem('realName'),
          subjectName: this.chooseTopicVal
        }
        } else {
          params = {
          taskId: this.taskId,
          operator: sessionStorage.getItem('realName'),
          year: this.chooseYearVal,
          issue: this.chooseIssueVal
        }
        }
      } else {
        params = {
          taskId: this.taskId,
          articleIds: articleIds,
          operator: sessionStorage.getItem('realName')
        }
        if (this.articleType) params.articleType = 1
      }
      params.column = this.columnVal
      SaveOfficialArticle(params).then((res) => {
        if (res && res.data && res.data.status === 0) {
          const { data } = res.data
          this.showOfficialWebsiteArticle = false
          this.$message({
            type: 'success',
            message: '设置成功'
          })
          this.$emit('SaveOfficialArticle')
        } else {
          this.$message.error(res.data.message)
        }
      })
    },
    // 微网刊确定
    weChatBtn(articleIds) {
      let params = {
        magId: this.magId,
        topic: this.publicationTopic,
        articleIds: articleIds,
        magName: this.platMagName,
        year: this.chooseYearVal,
        issue: this.chooseIssueVal,
        networkId: this.taskId
      }
      SaveOfficialArticleForWeChat(params).then((res) => {
        if (res && res.data && res.data.status === 0) {
          const { data } = res.data
          this.showOfficialWebsiteArticle = false
          this.$emit('SaveOfficialArticle')
        } else {
          this.$message.error(res.data.message)
        }
      })
    },
    // dialog关闭
    dialogClose() {
      this.chooseTypeVal = 1
      this.articleType = null
      // this.showChoose = false
    },
    // 点击每一篇文章时 类型为单篇任务
    handleRowClick(chooseId, row) {
      this.chooseId = chooseId
    }
  }
}
</script>

<style lang="scss" scoped>
.official-website-article {
  padding: 0px;
  h2 {
    text-align: left;
    background: #265cca;
    padding: 10px;
    color: white;
    height: 30px;
  }
  .download {
    margin-right: 20px;
  }
}
.form span {
  line-height: 34px;
  margin-right: 10px;
  margin-left: 20px;
}
.tj_year {
  font-size: 14px;
  font-weight: 600;
}
</style>
<style lang="scss">
.official-website-article-dialog {
  .el-dialog__wrapper {
    background: rgba(0, 0, 0, 0.3) !important;
  }
}
</style>
